








































import Vue from "vue";
import ssoMixin from "@/mixins/sso";
import { joinUrlQueryString } from "@/utils/util";
import LazyImg from "@/components/LazyImg.vue";
import fallback from "@/assets/images/banner.png";
import { IHomeBannerItem } from "@/types/post";

export default Vue.extend({
  name: "home-banner",
  mixins: [ssoMixin],
  components: {
    LazyImg,
  },
  data() {
    return {
      currentIndex: 1,
      fallback,
      reported: [],
    };
  },
  watch: {
    currentIndex(newIndex, oldIndex) {
      if (!this.reported.includes(newIndex)) {
        this.stat("xiaozhan_home_page_banner_show", {
          number: newIndex,
        });
        this.reported.push(newIndex);
      }
    },
  },
  computed: {
    curUser() {
      return this.$store.state.user.curUser;
    },
    hasLogin() {
      return this.curUser.userId !== "0";
    },
    banners() {
      return this.$store.state.post.homeBanner;
    },
    indicators() {
      return this.banners.map((item) => item.name) || [];
    },
    queryStr() {
      const queryObj = this.$route.query;
      const routeName = this.$route.name;
      const queryStr = joinUrlQueryString(queryObj, routeName);
      return queryStr;
    },
  },
  mounted() {
    setTimeout(() => {
      this.stat("xiaozhan_home_page_banner_show", {
        number: 1,
      });
      this.reported.push(1);
    }, 1000);
  },
  methods: {
    stat(action: string, data: any) {
      this.$stat("xiaozhan", action, data);
    },
    handleClick(index: number) {
      this.stat("xiaozhan_home_page_banner_click", {
        number: index + 1,
      });
    },
    handleChange(index: number) {
      this.currentIndex = index + 1;
    },
    displayStyle(item: IHomeBannerItem) {
      let styles: string[] = [];
      if (item.background) {
        styles.push(`background: ${item.background}`);
      }

      if (item.link_url) {
        styles.push(`cursor: pointer`);
      } else {
        styles.push(`cursor: initial`);
      }

      return styles.join(";");
    },
  },
});
