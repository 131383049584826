
































import Vue from "vue";
import SiteHeader from "@/components/Header.vue";
import SiteFooter from "@/components/Footer.vue";
import HomeBanner from "@/components/home/Banner.vue";
import HomeNav from "@/components/home/Nav.vue";
import HomeTopic from "@/components/home/Topic.vue";
import IndexList from "@/components/home/IndexList.vue";
import ContentEmpty from "@/components/Empty.vue";
import DownloadDialog from "@/components/DownloadDialog.vue";
import eventEmitter from "@/utils/eventEmitter";
import feedMixin from "@/mixins/feed";
import { IFeedItem } from "@/types/post";

export default Vue.extend({
  mixins: [feedMixin],
  components: {
    SiteHeader,
    SiteFooter,
    HomeBanner,
    HomeNav,
    HomeTopic,
    IndexList,
    ContentEmpty,
    DownloadDialog,
  },
  async asyncData({ store, req }) {
    try {
      const [bannerRes, topicRes, zoneRes, feedRes] = await Promise.all([
        store.dispatch("post/fetchHomeBanner", {}),
        store.dispatch("post/fetchHomeTopic", {}),
        store.dispatch("post/fetchZoneList", { type: 2 }),
        store.dispatch("post/fetchZoneResource", { refresh: true }),
      ]);

      return {
        banners: bannerRes.data,
        topic: topicRes.data,
        zones: zoneRes.data,
        feeds: feedRes.data,
      };
    } catch (e) {
      console.log("home ssr request error >>>", e);
      return {
        error: "error",
      };
    }
  },
  data() {
    return {
      title: "迅雷小站 - 精品资源下载站",
      error: "",
      downloadVisible: false,
      feedToDownload: {},
      loading: false,
      doctypeCid: 0,
      docNum: 16,
    };
  },
  head() {
    return {
      title: this.title,
      meta: [
        {
          hid: "description",
          name: "description",
          content:
            "迅雷小站是迅雷旗下的精品资源站，为广大用户提供海报、办公文档，设计素材，壁纸库以及在线编辑服务",
        },
        {
          hid: "keywords",
          name: "keywords",
          content:
            "迅雷小站,迅雷小站资源,迅雷小站资源下载,迅雷小站VIP精品资源下载,迅雷小站VIP会员资源下载,迅雷精品资源下载,迅雷小站精品资源,迅雷小站海报资源下载,迅雷小站办公文档下载,迅雷小站设计素材下载,迅雷小站壁纸库下载,迅雷小站精品壁纸库,迅雷小站壁纸库资源,迅雷精品办公文档下载,迅雷精品海报资源下载,迅雷小站精品海报资源,迅雷小站精品设计素材,迅雷小站精品办公文档,迅雷小站字体资源下载,迅雷实用字体资源下载,迅雷小站音乐资源下载,迅雷小站精品音乐资源,迅雷精品音乐资源下载,迅雷VIP精品资源下载站,迅雷小站资源下载网,精品资源下载网站,精品海报资源下载网站,精品办公文档下载网站,精品设计素材下载网站,热门资源下载网站推荐,精品资源下载网站推荐,精品资源网站在线下载,精品壁纸库资源下载网站",
        },
      ],
    };
  },
  computed: {
    curUser() {
      return this.$store.state.user.curUser;
    },
    hasLogin() {
      return this.curUser.userId !== "0";
    },
    zoneRes() {
      return this.$store.state.post.zoneHomeResource;
    },
    list() {
      return this.zoneRes.list;
    },
  },
  async mounted() {
    this.handleScroll();
    window.addEventListener("scroll", this.handleScroll);

    if (this.$refs.listElem) {
      // in case of ssr error
      const ssrError = this.$refs.listElem.getAttribute("data-ssr");
      if (ssrError === "error") {
        this.$store.dispatch("post/fetchHomeBanner", {});
        this.$store.dispatch("post/fetchHomeTopic", {});
        this.fetchZoneResList();
        await this.$store.dispatch("post/fetchZoneList", {
          type: 2,
        });
      }
    }

    eventEmitter.on("feed-download-fail", (item: IFeedItem) => {
      this.feedToDownload = item;
      this.downloadVisible = true;
    });

    eventEmitter.on("open-mac-download", ({ files, links }: any) => {
      this.feedToDownload = { files, directLinks: links };
      this.downloadVisible = true;
    });

    setTimeout(() => {
      this.stat("xiaozhan_home_page_show", {});
    }, 1000);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    stat(action: string, data: any) {
      this.$stat("xiaozhan", action, data);
    },
    async fetchZoneResList(refresh = true) {
      const params = {
        refresh,
      };
      await this.$store.dispatch("post/fetchZoneResource", params);
      this.loading = false;
    },
    handleScroll() {
      const scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (scrollTop > 130) {
        this.mode = "dark";
      } else {
        this.mode = "light";
      }
    },
  },
});
