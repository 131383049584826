












































import Vue from "vue";
import LazyImg from "@/components/LazyImg.vue";
import errorImg from "@/assets/img/error.png";
import { joinUrlQueryString } from "@/utils/util";
import { IZoneListItem } from "@/types/post";
import resizeMixin from "@/mixins/resize";

export default Vue.extend({
  name: "home-nav",
  mixins: [resizeMixin],
  components: {
    LazyImg,
  },
  props: {},
  data() {
    return {
      position: 0,
      arrowLeftVisible: false,
      arrowRightVisible: false,
      errorImg,
    };
  },
  computed: {
    zones() {
      return this.$store.state.post.zoneList;
    },
    queryStr() {
      const queryObj = this.$route.query;
      const routeName = this.$route.name;
      const queryStr = joinUrlQueryString(queryObj, routeName);
      return queryStr;
    },
    itemLen() {
      if (this.zones) {
        return this.zones.length;
      }
      return 0;
    },
  },
  mounted() {
    const contentList = this.zones
      .map((zone: IZoneListItem, index: number) => {
        return `zone_name=${encodeURIComponent(zone.name)},rn=${index}`;
      })
      .join(";");

    setTimeout(() => {
      this.stat("xiaozhan_zone_recommend_bar_show", {
        contenlist: encodeURIComponent(`{${contentList}}`),
      });
    }, 1000);
  },
  methods: {
    stat(action: string, data: any) {
      this.$stat("xiaozhan", action, data);
    },
    viewZone(item: IZoneListItem) {
      this.stat("xiaozhan_zone_recommend_bar_click", {
        zone_name: encodeURIComponent(item.name),
      });
    },
    handleLeftClick() {
      const sliderElem = this.$refs.sliderElem;
      if (sliderElem) {
        let itemWidth = 198;
        if (sliderElem.children && sliderElem.children[0]) {
          itemWidth = sliderElem.children[0].getBoundingClientRect().width;
        }

        const lastPosition = this.position;
        let currentPosition = lastPosition - 5 * itemWidth;
        const minTranslate = 0;
        if (currentPosition <= minTranslate) {
          currentPosition = minTranslate;
          this.arrowLeftVisible = false;
        }

        this.position = currentPosition;
        this.arrowRightVisible = true;

        sliderElem.style.transform = `translateX(-${currentPosition}px)`;
      }
    },
    handleRightClick() {
      const sliderElem = this.$refs.sliderElem;
      if (sliderElem) {
        const containerWidth = sliderElem.getBoundingClientRect().width || 1000;
        const children = sliderElem.children;
        const itemLen = children.length;
        let itemWidth = 198;
        if (children && children[0]) {
          itemWidth = children[0].getBoundingClientRect().width;
        }

        const listWidth = itemLen * itemWidth;
        const lastPosition = this.position;
        let currentPosition = lastPosition + 5 * itemWidth;
        const maxTranslate = listWidth - containerWidth;
        if (currentPosition >= maxTranslate) {
          currentPosition = maxTranslate;
          this.arrowRightVisible = false;
        }

        this.position = currentPosition;
        this.arrowLeftVisible = true;

        sliderElem.style.transform = `translateX(-${currentPosition}px)`;
      }
    },
  },
});
